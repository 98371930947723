import axios from "axios";
import yaml from "js-yaml";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

import { YamlEditor } from "./yaml-editor";

export const ExtraConfigEditor: React.FC = () => {
  const [yamlConfig, setYamlConfig] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchConfig = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/extra-config", { responseType: "text" });
      setYamlConfig(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching configuration:", error);
      toast.error("Failed to fetch configuration.");
      setLoading(false);
    }
  };

  const saveConfig = async (newYaml: string) => {
    setLoading(true);
    try {
      await axios.post("/api/extra-config", { yaml: newYaml });
      toast.success("Configuration updated successfully!");
      fetchConfig();
    } catch (error) {
      console.error("Error updating configuration:", error);
      toast.error("Failed to update configuration.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const restoreDefault = async () => {
    setLoading(true);
    try {
      await axios.post("/api/restore-default-extra-config");
      toast.success("Extra configuration restored to default successfully!");
      fetchConfig();
    } catch (error) {
      console.error("Error restoring default configuration:", error);
      toast.error("Failed to restore default configuration.");
    }
    setLoading(false);
  };

  if (isLoading) return <p>Loading...</p>;

  return (
    <div className="extra-config">
      <Toaster position="top-center" />
      <YamlEditor initialYaml={yamlConfig} onSave={saveConfig} onRestoreDefault={restoreDefault} />
    </div>
  );
};
