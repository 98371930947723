import { editor, languages } from "monaco-editor";
import React, { useState, useEffect } from "react";
import MonacoEditor from "react-monaco-editor";

languages.register({ id: "yaml" });

languages.setMonarchTokensProvider("yaml", {
  tokenizer: {
    root: [
      [/\s*#.*/, "comment.yaml"], // YAML comments
      [/\b(true|false|null)\b/, "constant.language.yaml"], // Constants
      [/\b([a-z0-9A-Z_]+?):/, "attribute.name.yaml"], // Keys
      [/"([^"\\]|\\.)*"/, "string.yaml"], // Double-quoted string
      [/'([^'\\]|\\.)*'/, "string.yaml"], // Single-quoted string
      [/\b\d+\b/, "number.yaml"], // Numbers
      [/[{}\[\]]/, "operators.yaml"], // Operators (braces, brackets)
      [/-\s*(?=[\[{]?[\w"])/, "array:yaml"], // Arrays/lists
    ],
  },
});

editor.defineTheme("customYamlTheme", {
  base: "vs-dark", // Base theme
  inherit: true, // Inherit all the default rules from the dark theme
  rules: [
    { token: "comment.yaml", foreground: "7ca668", fontStyle: "italic" },
    { token: "string.yaml", foreground: "ce9178" },
    { token: "number.yaml", foreground: "b5cea8" },
    { token: "attribute.name.yaml", foreground: "569cd6" },
    { token: "array:yaml", foreground: "dcdcaa" },
    { token: "operators.yaml", foreground: "4EC9B0" },
    { token: "constant.language.yaml", foreground: "569CD6" },
  ],
  colors: {
    "editor.foreground": "#FFFFFF",
    "editor.background": "#1E1E1E",
    "editorCursor.foreground": "#D4D4D4",
    "editor.lineHighlightBackground": "#264F78",
    "editorLineNumber.foreground": "#858585",
    "editor.selectionBackground": "#264F78",
    "editor.inactiveSelectionBackground": "#3A3D41",
  },
});

interface YamlEditorProps {
  initialYaml: string;
  onSave: (yaml: string) => void;
  onRestoreDefault?: () => void;
}

export const YamlEditor: React.FC<YamlEditorProps> = ({
  initialYaml,
  onSave,
  onRestoreDefault,
}) => {
  const [code, setCode] = useState<string>(initialYaml);

  useEffect(() => {
    setCode(initialYaml);
  }, [initialYaml]);

  const handleEditorChange = (newValue: string, e: editor.IModelContentChangedEvent) => {
    setCode(newValue);
  };

  const options: editor.IStandaloneEditorConstructionOptions = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    minimap: { enabled: false },
  };

  return (
    <div className="yaml-editor">
      <MonacoEditor
        height="600"
        language="yaml"
        theme="vs-dark"
        value={code}
        options={options}
        onChange={handleEditorChange}
      />
      <div className="save-button-wrapper">
        {onRestoreDefault && (
          <div className="restore-button" onClick={onRestoreDefault}>
            Restore Default
          </div>
        )}
        <div className="save-button" onClick={() => onSave(code)}>
          Save Configuration
        </div>
      </div>
    </div>
  );
};
