// file: entry-list-config.tsx
import axios from "axios";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

import { INIEditor } from "./ini-editor";

export const EntryListConfigEditor: React.FC = () => {
  const [iniConfig, setIniConfig] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchConfig = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/entry-list-config", { responseType: "text" });
      setIniConfig(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching entry list configuration:", error);
      toast.error("Failed to fetch entry list configuration.");
      setLoading(false);
    }
  };

  const saveConfig = async (newIni: string) => {
    setLoading(true);
    try {
      await axios.post("/api/entry-list-config", { ini: newIni });
      toast.success("Entry list configuration updated successfully!");
      fetchConfig();
    } catch (error) {
      console.error("Error updating entry list configuration:", error);
      toast.error("Failed to update entry list configuration.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  const restoreDefault = async () => {
    setLoading(true);
    try {
      await axios.post("/api/restore-default-entry-list-config");
      toast.success("Entry List configuration restored to default successfully!");
      fetchConfig();
    } catch (error) {
      console.error("Error restoring default configuration:", error);
      toast.error("Failed to restore default configuration.");
    }
    setLoading(false);
  };

  if (isLoading) return <p>Loading...</p>;

  return (
    <div className="entry-list-config-editor">
      <Toaster position="top-center" />
      <INIEditor initialIni={iniConfig} onSave={saveConfig} onRestoreDefault={restoreDefault} />
    </div>
  );
};
