import { editor } from "monaco-editor";
import React, { useEffect, useState } from "react";
import MonacoEditor from "react-monaco-editor";

interface InstructionsProps {
  instructionsContent: string;
}

const instructions = `
After installing Assetto Corsa \`and playing it for the first time\` to configure your graphics and controls settings, do the following:

# 1- Install Content Manager:
  > Download: https://x4fab.itch.io/ac-content-manager

# 2- Install this version of SOL:
  > Download: https://mgz.me/publicfiles/mods/Sol%202.2.9.7z
  > Install instructions: https://mgz.me/publicfiles/mods/Sol_2.2.8_install_guide.pdf

# 3- Install Custom Shaders Patch:
  > Download: https://mgz.me/publicfiles/mods/lights-patch-v0.2.2-preview1-full.zip
  > Install instructions: \`drag and drop the download file into Content Manager\`.
  > *This is a paid mod. Please support the author here*: https://www.patreon.com/x4fab/posts

# 4- Install Shutoko **Public Test Beta 0.9.3**:
  > Download: https://mgz.me/publicfiles/mods/SRP%200.9.3%20PTB1%20Release.7z
  > Install instructions: \`drag and drop the download file into Content Manager\`.

# 5- Install LA Canyons VIP:
  > Download: https://mgz.me/publicfiles/mods/la_canyons.7z
  > Install instructions: **DON'T drag into Content Manager**. \`Read the README\` inside the 7z file.
  > *This is a paid mod. Please support the author here*: https://phoenix-mods.sellfy.store/p/fomi6y/

# 6- Install Pacific Coast VIP:
  > Download: https://mgz.me/publicfiles/mods/pacific_coast.7z
  > Install instructions: **DON'T drag into Content Manager**. \`Read the README\` inside the 7z file.
  > *This is a paid mod. Please support the author here*: https://phoenix-mods.sellfy.store/p/pacific-coast-extras-pack/

# 7- DLCs (may be required depending on the server config):
  > Dream Pack 1       - https://store.steampowered.com/app/347990/Assetto_Corsa__Dream_Pack_1                  (347990)
  > Dream Pack 2       - https://store.steampowered.com/app/404430/Assetto_Corsa__Dream_Pack_2                  (404430)
  > Dream Pack 3       - https://store.steampowered.com/app/423630/Assetto_Corsa__Dream_Pack_3                  (423630)
  > Japanese Pack      - https://store.steampowered.com/app/467980/Assetto_corsa__Japanese_Pack                 (467980)
  > Red Pack           - https://store.steampowered.com/app/493190/Assetto_Corsa__Red_Pack                      (493190)
  > Tripl3 Pack        - https://store.steampowered.com/app/509350/Assetto_Corsa_Tripl3_Pack                    (509350)
  > Porsche Pack I     - https://store.steampowered.com/app/526060/Assetto_Corsa__Porsche_Pack_I                (526060)
  > Porsche Pack II	   - https://store.steampowered.com/app/540710/Assetto_Corsa__Porsche_Pack_II               (540710)
  > Porsche Pack III   - https://store.steampowered.com/app/540711/Assetto_Corsa__Porsche_Pack_III              (540711)
  > Ready to Race Pack - https://store.steampowered.com/app/619680/Assetto_Corsa__Ready_To_Race_Pack            (619680)
  > Ferrari Pack       - https://store.steampowered.com/app/675590/Assetto_Corsa__Ferrari_70th_Anniversary_Pack (675590)

`;

export const Instructions: React.FC = () => {
  const options: editor.IStandaloneEditorConstructionOptions = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    minimap: { enabled: false },
    readOnly: true,
    lineNumbers: "off" as editor.LineNumbersType,
  };

  return (
    <div className="ini-editor">
      <MonacoEditor
        height="600"
        language="markdown"
        theme="vs-dark"
        value={instructions}
        options={options}
      />
    </div>
  );
};
