import axios from "axios";
import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

import { INIEditor } from "./ini-editor";

export const ServerConfigEditor: React.FC = () => {
  const [iniConfig, setIniConfig] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [presets, setPresets] = useState([]);

  const fetchConfig = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/server-config", { responseType: "text" });
      setIniConfig(response.data);
      const presetsResponse = await axios.get("/api/track-presets");
      setPresets(presetsResponse.data);
    } catch (error) {
      console.error("Error fetching server configuration or presets:", error);
      toast.error("Failed to fetch server configuration or presets.");
    } finally {
      setLoading(false);
    }
  };

  const saveConfig = async (newIni: string) => {
    setLoading(true);
    try {
      await axios.post("/api/server-config", { ini: newIni });
      toast.success("Server configuration updated successfully!");
      fetchConfig();
    } catch (error) {
      console.error("Error updating server configuration:", error);
      toast.error("Failed to update server configuration.");
    } finally {
      setLoading(false);
    }
  };

  const restoreDefault = async () => {
    setLoading(true);
    try {
      await axios.post("/api/restore-default-server-config");
      toast.success("Server configuration restored to default successfully!");
      fetchConfig();
    } catch (error) {
      console.error("Error restoring server configuration to default:", error);
      toast.error("Failed to restore server configuration to default.");
    }
    setLoading(false);
  };

  const updateTrack = async (presetId: string) => {
    setLoading(true);
    try {
      await axios.post("/api/update-track", { preset: presetId });
      toast.success("Track updated successfully!");
      fetchConfig();
    } catch (error) {
      console.error("Error updating the track:", error);
      toast.error("Failed to update the track.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  if (isLoading) return <p>Loading...</p>;

  return (
    <div className="server-config-editor">
      <Toaster position="top-center" />
      <INIEditor
        initialIni={iniConfig}
        onSave={saveConfig}
        onRestoreDefault={restoreDefault}
        presets={presets}
        onUpdateTrack={updateTrack}
      />
    </div>
  );
};
