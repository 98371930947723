import React, { useEffect, useState } from "react";
import MonacoEditor from "react-monaco-editor";

interface INIEditorProps {
  initialIni: string;
  onSave: (ini: string) => void;
  onRestoreDefault?: () => void;
  presets?: any[];
  onUpdateTrack?: (presetId: string) => void;
}

export const INIEditor: React.FC<INIEditorProps> = ({
  initialIni,
  onSave,
  onRestoreDefault,
  presets,
  onUpdateTrack,
}) => {
  const [code, setCode] = useState(initialIni);

  useEffect(() => {
    setCode(initialIni);
  }, [initialIni]);

  const handleEditorChange = (newValue: string) => {
    setCode(newValue);
  };

  const options = {
    selectOnLineNumbers: true,
    automaticLayout: true,
    minimap: { enabled: false },
  };

  return (
    <div className="ini-editor">
      <MonacoEditor
        height="600"
        language="ini"
        theme="vs-dark"
        value={code}
        options={options}
        onChange={handleEditorChange}
      />
      <div className="save-button-wrapper">
        {onRestoreDefault && (
          <div className="restore-button" onClick={onRestoreDefault}>
            Restore Default
          </div>
        )}
        {presets && onUpdateTrack && (
          <div className="preset-selector">
            <select onChange={(e) => onUpdateTrack(e.target.value)} defaultValue="">
              <option value="" disabled>
                Select Track Preset
              </option>
              {presets.map((preset) => (
                <option key={preset.id} value={preset.id}>
                  {preset.beautifulName}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="save-button" onClick={() => onSave(code)}>
          Save Configuration
        </div>
      </div>
    </div>
  );
};
