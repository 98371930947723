import React, { useEffect, useRef } from "react";

import { useLogs } from "../stores/log-context";

export const Logs: React.FC = () => {
  const { logs } = useLogs();
  const endOfLogsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (endOfLogsRef.current) {
      endOfLogsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [logs]);

  return (
    <div className="logs-display">
      <pre>
        {logs.map((log, index) => (
          <div key={index} className="log-line">
            {log}
          </div>
        ))}
        <div ref={endOfLogsRef} />
      </pre>
    </div>
  );
};
