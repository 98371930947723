import React from "react";
import ReactDOM from "react-dom/client";

import "./styles/style.scss";
import { App } from "./app";
import { LogProvider } from "./stores/log-context";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <LogProvider>
      <App />
    </LogProvider>,
  );
} else {
  console.error("Failed to find the root element");
}
