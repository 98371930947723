import React, { createContext, useContext, useState, ReactNode, useCallback } from "react";

interface LogContextType {
  logs: string[];
  addLog: (log: string) => void;
}

const LogContext = createContext<LogContextType | undefined>(undefined);

export const useLogs = () => {
  const context = useContext(LogContext);
  if (!context) {
    throw new Error("useLogs must be used within LogProvider");
  }
  return context;
};

export const LogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [logs, setLogs] = useState<string[]>([]);

  const addLog = useCallback((log: string) => {
    setLogs((currentLogs) => [...currentLogs.slice(-4999), log]); // Keep only the latest 5000 logs
  }, []);

  return <LogContext.Provider value={{ logs, addLog }}>{children}</LogContext.Provider>;
};
