import React, { useEffect, useState } from "react";

import { EntryListConfigEditor } from "./components/entry-list-config";
import { ExtraConfigEditor } from "./components/extra-config";
import { Instructions } from "./components/instructions";
import { Logs } from "./components/logs";
import { ServerConfigEditor } from "./components/server-config";
import { ServerInfo } from "./components/server-info";
import { useLogs } from "./stores/log-context";

(window.process as any) = {
  env: { NODE_ENV: "development" },
};

type ContentTabs = "server-config" | "extra-config" | "entry-list-config" | "instructions" | "logs";

export const App = () => {
  const [activeTab, setActiveTab] = useState<ContentTabs>("instructions");
  const { addLog } = useLogs();

  useEffect(() => {
    const fetchInitialLogs = async () => {
      const response = await fetch("/api/logs");
      const data = await response.json();
      data.forEach((log: string) => addLog(log));
    };
    fetchInitialLogs();

    const websocketURL = process.env.REACT_APP_WEBSOCKET_URL!;
    console.log(websocketURL);
    const ws = new WebSocket(websocketURL);

    ws.onmessage = (event) => {
      addLog(event.data);
    };

    ws.onopen = () => console.log("WebSocket connected");
    ws.onerror = (error) => console.error("WebSocket error:", error);
    ws.onclose = () => console.log("WebSocket disconnected");

    return () => {
      ws.close();
    };
  }, [addLog]);

  return (
    <>
      <ServerInfo />
      <div className="tabs">
        <div
          className={`tab-button ${activeTab === "instructions" ? "active" : ""}`}
          onClick={() => setActiveTab("instructions")}
        >
          Instructions
        </div>
        <div
          className={`tab-button ${activeTab === "server-config" ? "active" : ""}`}
          onClick={() => setActiveTab("server-config")}
        >
          Server Config
        </div>
        <div
          className={`tab-button ${activeTab === "extra-config" ? "active" : ""}`}
          onClick={() => setActiveTab("extra-config")}
        >
          Extra Config
        </div>
        <div
          className={`tab-button ${activeTab === "entry-list-config" ? "active" : ""}`}
          onClick={() => setActiveTab("entry-list-config")}
        >
          Entry List Config
        </div>
        <div
          className={`tab-button ${activeTab === "logs" ? "active" : ""}`}
          onClick={() => setActiveTab("logs")}
        >
          Server Logs
        </div>
      </div>
      {activeTab === "server-config" && <ServerConfigEditor />}
      {activeTab === "extra-config" && <ExtraConfigEditor />}
      {activeTab === "entry-list-config" && <EntryListConfigEditor />}
      {activeTab === "instructions" && <Instructions />}
      {activeTab === "logs" && <Logs />}
    </>
  );
};
