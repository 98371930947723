import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

interface ServerInfoType {
  status: "running" | "not running";
  runningSince?: string;
  message?: string;
  inviteLink?: string;
  pid?: string;
  "%CPU"?: string;
  "%MEM"?: string;
  VSZ?: string;
  RSS?: string;
  CMD?: string;
}

export const ServerInfo = () => {
  const [serverInfo, setServerInfo] = useState<ServerInfoType | null>(null);
  const [formattedDuration, setFormattedDuration] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [serverRunning, setServerRunning] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleServerAction = (url: string) => {
    const fetchPromise = fetch(url, { method: "POST" })
      .then((response) => response.json())
      .then((data) => {
        if (!data.message) {
          throw new Error("Unexpected response from server");
        }
        return data.message;
      });

    toast.promise(fetchPromise, {
      loading: "Processing...",
      success: (data) => <b>{data}</b>,
      error: (err) => <b>{err.toString()}</b>,
    });
  };

  const formatDuration = (startDate: string) => {
    const start = new Date(startDate);
    const now = new Date();
    const diff = now.getTime() - start.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    const startTimeFormatted = start.toLocaleString();
    const durationFormatted = `${days > 0 ? `${days} days, ` : ""}${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    return `${startTimeFormatted} (${durationFormatted})`;
  };

  useEffect(() => {
    const fetchProcessInfo = async () => {
      try {
        const response = await fetch("/process-info");
        const data: ServerInfoType = await response.json();
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        setServerInfo(data);
        setServerRunning(data.status === "running");
        setError("");
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };

    const intervalId = setInterval(fetchProcessInfo, 3000);
    fetchProcessInfo();

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (serverInfo?.runningSince) {
      timerId = setInterval(() => {
        setFormattedDuration(formatDuration(serverInfo.runningSince!));
      }, 1000);
    }

    return () => clearInterval(timerId);
  }, [serverInfo?.runningSince]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="server-status">
      <Toaster />
      <h1>
        {serverInfo?.status === "running" ? `Running Since: ${formattedDuration}` : "Not Running"}
      </h1>
      {serverInfo && serverInfo.status === "running" ? (
        <>
          <table>
            <thead>
              <tr>
                {Object.keys(serverInfo)
                  .filter(
                    (key) =>
                      key !== "status" &&
                      key !== "runningSince" &&
                      key !== "message" &&
                      key !== "inviteLink",
                  )
                  .map((key) => (
                    <th key={`header-${key}`}>{key.replace(/%/g, "")}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {Object.entries(serverInfo)
                  .filter(
                    ([key, _]) =>
                      key !== "status" &&
                      key !== "runningSince" &&
                      key !== "message" &&
                      key !== "inviteLink",
                  )
                  .map(([key, value]) => (
                    <td key={key}>
                      {key.toLowerCase() === "%mem" || key.toLowerCase() === "%cpu"
                        ? `${value} %`
                        : value}
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
          {serverInfo?.inviteLink && (
            <div className="invite-link">
              join:{" "}
              <a href={serverInfo.inviteLink} target="_blank" rel="noreferrer">
                {serverInfo.inviteLink}
              </a>
            </div>
          )}
        </>
      ) : (
        <p>{serverInfo?.message}</p>
      )}
      <div className="server-actions">
        <div
          className={`buttons stop-server-button${serverRunning ? "" : " disabled"}`}
          onClick={() => handleServerAction("/stop-server")}
        >
          Stop Server
        </div>
        <div
          className={`buttons restart-server-button${serverRunning ? "" : " disabled"}`}
          onClick={() => handleServerAction("/restart-server")}
        >
          Restart Server
        </div>
        <div
          className={`buttons start-server-button${serverRunning ? " disabled" : ""}`}
          onClick={() => handleServerAction("/start-server")}
        >
          Start Server
        </div>
      </div>
    </div>
  );
};
